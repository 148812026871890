import { Injectable } from '@angular/core'
import { ContentSubtask } from '@modules/content-task/models/content-task.interface'
import { ContentTaskApiService } from '@modules/content-task/services/content-task-api.service'
import { Todo } from '@modules/todo/models/todo.model'
import { TodoStateService } from '@modules/todo/states/todo-state.service'
import { BehaviorSubject } from 'rxjs'
import { AlertService } from './alert.service'

interface ITodo extends Todo {
    type?: 'todo' | 'requirement'
}

@Injectable({
    providedIn: 'root',
})
export class PomodoroService {
    private _selectedTodo = new BehaviorSubject<ITodo | null>(null)
    selectedTodo$ = this._selectedTodo.asObservable()
    task: ITodo

    constructor(
        private alertService: AlertService,
        public todoStateService: TodoStateService,
        private contentTaskApiService: ContentTaskApiService,
    ) {}

    toggleTodoStatus(todo: Todo) {
        const updatedTodo = { ...todo, completed: !todo.completed }
        this.todoStateService.toggleTodoCompleted(todo)
        if (this._selectedTodo.value?.id === todo.id) {
            this._selectedTodo.next(updatedTodo)
        }
    }

    // updateSubtask(taskId: string, subtask: ContentSubtask, index: number, event) {
    //     // console.log(taskId, subtask, index, event)
    //     const completed = event.target.checked
    //     this.task.completed = completed
    //     // console.log(completed)
    //     this.contentTaskApiService.updateSubtask(taskId, subtask, completed).subscribe({
    //         next: () => {
    //             this.alertService.success('Saved')
    //         },
    //     })
    // }

    selectTodo(todo: ITodo | null): void {
        this._selectedTodo.next(todo)
        this.task = todo
    }

    unSelectTodo() {
        this._selectedTodo.next(null)
    }
}
