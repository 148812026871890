<div class="rounded-md border bg-base-100 px-3 py-5">
    <div class="time">{{ formatTime(remainingTime) }}</div>
    <div class="relative my-7">
        @if (!isRunning) {
            <svg
                (click)="startTimer()"
                class="mx-auto cursor-pointer"
                xmlns="http://www.w3.org/2000/svg"
                height="80px"
                viewBox="0 0 24 24"
                width="80px"
                fill="oklch(53.92% 0.162 241.36 / 1)"
            >
                <path
                    d="M8 6.82v10.36c0 .79.87 1.27 1.54.84l8.14-5.18c.62-.39.62-1.29 0-1.69L9.54 5.98C8.87 5.55 8 6.03 8 6.82z"
                />
            </svg>
        }
        @if (isRunning) {
            <svg
                (click)="pauseTimer()"
                class="mx-auto cursor-pointer"
                xmlns="http://www.w3.org/2000/svg"
                height="80px"
                viewBox="0 0 24 24"
                width="80px"
                fill="oklch(53.92% 0.162 241.36 / 1)"
            >
                <path
                    d="M8 19c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2s-2 .9-2 2v10c0 1.1.9 2 2 2zm6-12v10c0 1.1.9 2 2 2s2-.9 2-2V7c0-1.1-.9-2-2-2s-2 .9-2 2z"
                />
            </svg>
        }
        <div class="absolute right-10 top-1/2">
            <svg
                (click)="resetTimer()"
                class="cursor-pointer"
                xmlns="http://www.w3.org/2000/svg"
                height="30px"
                viewBox="0 0 24 24"
                width="30px"
                fill="oklch(53.92% 0.162 241.36 / 1)"
            >
                <path
                    d="M12 5V2.21c0-.45-.54-.67-.85-.35l-3.8 3.79c-.2.2-.2.51 0 .71l3.79 3.79c.32.31.86.09.86-.36V7c3.73 0 6.68 3.42 5.86 7.29-.47 2.27-2.31 4.1-4.57 4.57-3.57.75-6.75-1.7-7.23-5.01-.07-.48-.49-.85-.98-.85-.6 0-1.08.53-1 1.13.62 4.39 4.8 7.64 9.53 6.72 3.12-.61 5.63-3.12 6.24-6.24C20.84 9.48 16.94 5 12 5z"
                />
            </svg>
        </div>
    </div>
    @if (todo) {
        <div>
            <div class="flex items-center gap-3 text-left text-lg font-bold">
                {{ current === 1 ? 'Focus Cycle' : 'Focus Cycles' }}
                <div class="!mt-3 flex items-center text-left text-lg font-bold">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="20px"
                        viewBox="0 0 24 24"
                        width="20px"
                        fill="#000"
                        class="mt-2 cursor-pointer"
                        (click)="incrementCurrent()"
                    >
                        <path d="M0 0h24v24H0V0z" fill="none" />
                        <path
                            d="M18 13h-5v5c0 .55-.45 1-1 1s-1-.45-1-1v-5H6c-.55 0-1-.45-1-1s.45-1 1-1h5V6c0-.55.45-1 1-1s1 .45 1 1v5h5c.55 0 1 .45 1 1s-.45 1-1 1z"
                        />
                    </svg>
                    <p class="mt-2 text-3xl">{{ current }}</p>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="20px"
                        viewBox="0 0 24 24"
                        width="20px"
                        fill="#000"
                        class="mt-2 cursor-pointer"
                        (click)="decrementCurrent()"
                    >
                        <path d="M0 0h24v24H0V0z" fill="none" />
                        <path
                            d="M18 13H6c-.55 0-1-.45-1-1s.45-1 1-1h12c.55 0 1 .45 1 1s-.45 1-1 1z"
                        />
                    </svg>
                </div>
            </div>
            <div class="flex items-center gap-2">
                <p class="text-left text-lg font-bold">{{ todo.text }}</p>
                <input
                    (change)="toggleTodoStatus()"
                    type="checkbox"
                    checked="{{ todo.completed ? 'checked' : '' }}"
                    class="checkbox checkbox-sm"
                />
                <div class="tooltip mt-2">
                    <svg
                        (click)="unSelectTodo()"
                        class="h-[25px] w-[25px] cursor-pointer rounded-full !bg-green-400 p-1 text-lg"
                        xmlns="http://www.w3.org/2000/svg"
                        height="40px"
                        viewBox="0 0 24 24"
                        width="40px"
                        fill="#ffffff"
                    >
                        <path d="M0 0h24v24H0V0z" fill="none" />
                        <path
                            d="M11 5v11.17l-4.88-4.88c-.39-.39-1.03-.39-1.42 0-.39.39-.39 1.02 0 1.41l6.59 6.59c.39.39 1.02.39 1.41 0l6.59-6.59c.39-.39.39-1.02 0-1.41-.39-.39-1.02-.39-1.41 0L13 16.17V5c0-.55-.45-1-1-1s-1 .45-1 1z"
                        />
                    </svg>
                    <span class="tooltiptext">End Pomodoro Session</span>
                </div>
            </div>
        </div>
    }
    <div class="flex justify-center gap-2 !pt-4">
        <button
            class="session-btn"
            [ngClass]="{ active: currentSession === 'work' }"
            (click)="setSession('work')"
        >
            Focus
        </button>
        <button
            class="session-btn"
            [ngClass]="{ active: currentSession === 'shortBreak' }"
            (click)="setSession('shortBreak')"
        >
            Short Break
        </button>
        <button
            class="session-btn"
            [ngClass]="{ active: currentSession === 'longBreak' }"
            (click)="setSession('longBreak')"
        >
            Long Break
        </button>
    </div>
</div>
