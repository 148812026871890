import { CommonModule } from '@angular/common'
import { Component, EventEmitter, Input, Output, SimpleChanges, inject } from '@angular/core'

import { PomodoroService } from '@core/services/pomodoro.service'
import { TodoStateService } from '@modules/todo/states/todo-state.service'
import { Todo } from '../models/todo.model'

interface ITodo extends Todo {
    type?: 'todo' | 'requirement'
}
@Component({
    selector: 'app-pomodoro-timer',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './pomodoro-timer.component.html',
    styleUrls: ['./pomodoro-timer.component.scss'],
})
export class PomodoroTimerComponent {
    private pomodoroService = inject(PomodoroService)
    public todoStateService = inject(TodoStateService)

    @Input() todo: ITodo
    @Output() setSelectedTodo = new EventEmitter<ITodo | null>()
    @Output() toggleTodo = new EventEmitter<ITodo>()

    current = 0
    workDuration = 25 * 60
    shortBreakDuration = 5 * 60
    longBreakDuration = 15 * 60
    remainingTime = this.workDuration
    isRunning = false
    currentSession: 'work' | 'shortBreak' | 'longBreak' = 'work'
    timerInterval: any
    alarm: HTMLAudioElement

    constructor() {
        this.alarm = new Audio('assets/cerp-pomodoro-timer.wav')
    }

    ngOnInit(): void {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['todo'] && !changes['todo'].firstChange) {
            this.current = 0
        }
    }
    unSelectTodo() {
        this.pomodoroService.unSelectTodo()
    }

    toggleTodoStatus() {
        this.todoStateService.toggleTodoCompleted(this.todo)
        this.todo.completed = !this.todo.completed
    }

    startTimer(): void {
        if (!this.isRunning) {
            this.isRunning = true
            this.timerInterval = setInterval(() => {
                if (this.remainingTime > 0) {
                    this.remainingTime--
                } else {
                    this.triggerAlarm()
                    this.switchSession()
                }
            }, 1000)
        }
    }

    pauseTimer(): void {
        this.isRunning = false
        clearInterval(this.timerInterval)
    }

    resetTimer(): void {
        this.isRunning = false
        clearInterval(this.timerInterval)
        this.setSession(this.currentSession)
    }

    switchSession(): void {
        if (this.currentSession === 'work') {
            this.current++
            this.setSession('shortBreak')
        } else {
            this.setSession('work')
        }
    }

    setSession(session: 'work' | 'shortBreak' | 'longBreak'): void {
        this.pauseTimer()
        this.currentSession = session
        this.remainingTime =
            session === 'work'
                ? this.workDuration
                : session === 'shortBreak'
                  ? this.shortBreakDuration
                  : this.longBreakDuration
        if (this.isRunning) {
            this.startTimer()
        }
    }

    formatTime(seconds: number): string {
        const minutes: number = Math.floor(seconds / 60)
        const secs: number = seconds % 60
        return `${minutes}:${secs < 10 ? '0' : ''}${secs}`
    }

    triggerAlarm(): void {
        if ('Notification' in window && Notification.permission === 'granted') {
            this.alarm.play()
            const notification = new Notification('Content ERP', {
                body:
                    this.currentSession === 'work'
                        ? 'Time for a break!'
                        : 'Time to get back to work!',
            })

            notification.onclick = () => {
                window.focus()
            }
        }
    }

    incrementCurrent() {
        this.current++
    }
    decrementCurrent() {
        if (this.current > 0) this.current--
    }
}
