import { Injectable, inject } from '@angular/core'
import { FormControl } from '@angular/forms'
import { SimpleStore } from '@core/states/simple-store'
import { AuthStateService } from '@modules/auth/states/auth-state.service'
import { ContentSubtask } from '@modules/content-task/models/content-task.interface'
import { Todo } from '../models/todo.model'
import { TodoApiService } from '../services/todo-api.service'

export interface TodoState {
    todos: Todo[]
    loading: boolean
}

export const initialState: TodoState = {
    todos: [],
    loading: false,
}

@Injectable({
    providedIn: 'root',
})
export class TodoStateService extends SimpleStore<TodoState> {
    private todos: Todo[] = []
    private todoApiService = inject(TodoApiService)
    private authStateService = inject(AuthStateService)
    todoTextControl = new FormControl('')

    constructor() {
        super(initialState)
    }

    getTodos(): Todo[] {
        return this.todos
    }

    init() {
        this.loadTodos()
    }

    saveTodo(todo?: string) {
        let text: string
        if (todo) {
            text = todo
        } else {
            text = this.todoTextControl.value
        }
        this.setState({ loading: true })
        this.todoApiService.create({ text }).subscribe({
            next: (res) => {
                this.setState({ todos: [res.data, ...this.getState().todos], loading: false })
                this.todoTextControl.setValue('')
            },
            error: (error) => {
                this.setState({ loading: false })
            },
        })
    }

    deleteTodo(todo: Todo) {
        this.todoApiService.delete(todo.id).subscribe({
            next: (res) => {
                const todoIndex = this.getState().todos.findIndex((t) => t.id === todo.id)
                this.setState({
                    todos: [
                        ...this.getState().todos.slice(0, todoIndex),
                        ...this.getState().todos.slice(todoIndex + 1),
                    ],
                })
            },
            error: (err) => {},
        })
    }

    toggleTodoCompleted(todo: Todo) {
        this.todoApiService.update(todo.id, { completed: !todo.completed }).subscribe({
            next: (res) => {
                const todoIndex = this.getState().todos.findIndex((t) => t.id === todo.id)
                this.setState({
                    todos: [
                        ...this.getState().todos.slice(0, todoIndex),
                        res.data,
                        ...this.getState().todos.slice(todoIndex + 1),
                    ],
                })
            },
            error: (err) => {},
        })
    }

    private loadTodos(): void {
        this.todoApiService
            .find({
                page: 1,
                size: 100,
                orderBy: 'desc',
                sortBy: 'createdAt',
                creatorId: this.authStateService.getUserId(),
            })
            .subscribe({
                next: (res) => {
                    this.setState({ todos: res.data })
                },
            })
    }
}
