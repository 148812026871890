import { HttpClient } from '@angular/common/http'
import { Injectable, inject } from '@angular/core'
import { ApiResponse } from '@core/models/api-response.model'
import { ApiService } from '@core/services/api.service'
import { Observable } from 'rxjs'
import { Todo } from '../models/todo.model'

@Injectable({
    providedIn: 'root',
})
export class TodoApiService extends ApiService<Todo, Todo> {
    protected http: HttpClient

    constructor() {
        const http = inject(HttpClient)

        super(http, 'v2/todos')

        this.http = http
    }

    markAsRead(id: string): Observable<ApiResponse<Todo>> {
        return this.update(id, { completed: true })
    }
}
